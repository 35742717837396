import React from 'react';
import { useTranslation } from 'react-i18next';

const testimonials = [
  {
    name: 'Emily Johnson',
    title: 'Freelance_Writer',
    text: 'Writer_text',
    img: 'https://randomuser.me/api/portraits/women/22.jpg'
  },
  {
    name: 'Michael Smith',
    title: 'SEO_Specialist',
    text: 'Seo_text',
    img: 'https://randomuser.me/api/portraits/men/33.jpg'
  },
  {
    name: 'Sarah Williams',
    title: 'Graduate_Student',
    text: 'Student_text',
    img: 'https://randomuser.me/api/portraits/women/34.jpg'
  },
  {
    name: 'David Brown',
    title: 'Tech_Blogger',
    text: 'Blogger_text',
    img: 'https://randomuser.me/api/portraits/men/45.jpg'
  },
  {
    name: 'Jessica Davis',
    title: 'Data_Analyst',
    text: 'Analyst_text',
    img: 'https://randomuser.me/api/portraits/women/50.jpg'
  },
  {
    name: 'John Miller',
    title: 'Novelist',
    text: 'Novelist_text',
    img: 'https://randomuser.me/api/portraits/men/51.jpg'
  }
];

const Testimonials = () => {
  const { t } = useTranslation();
  return (
    <div className="container mt-5">
      <h2 className="text-center">{t('User_Reviews')}</h2>
      <div className="row">
        {testimonials.map((testimonial, index) => (
          <div className="col-md-4 mb-4" key={index}>
            <div className="card h-100 text-center">
              <img src={testimonial.img} className="card-img-top" alt={testimonial.name} style={{ borderRadius: '50%', width: '100px', height: '100px', margin: '20px auto 10px auto' }} />
              <div className="card-body">
                <h5 className="card-title">{testimonial.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{t(testimonial.title)}</h6>
                <p className="card-text">{t(testimonial.text)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
