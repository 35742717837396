import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Alert } from 'react-bootstrap'; // 保持原有的组件
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { FaExclamationCircle } from 'react-icons/fa'; // 保留引入感叹号图标
import { useTranslation } from 'react-i18next'; // 引入 useTranslation


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentModal = ({ show, handleClose, selectedPackage, currency}) => {
  const [clientSecret, setClientSecret] = useState('');
  const [step, setStep] = useState(1); // 1 表示订单摘要，2 表示支付
  const { t } = useTranslation(); // 初始化 t 函数
  const [promoCode, setPromoCode] = useState(''); // Promo code state
  const [isPromoValid, setIsPromoValid] = useState(true); // 是否为有效推广码
  const [referral, setReferral] = useState(false); // referral 默认 false
  const [finalCredits, setFinalCredits] = useState(selectedPackage.credits); // 最终 credits
  const [promoCodeError, setPromoCodeError] = useState(''); // 用于显示推广码错误的提示

  console.log(selectedPackage)
  
  useEffect(() => {
    if (show) {
      setStep(1); // 当模态框打开时重置为订单摘要步骤
      setPromoCode(''); // 重置推广码
      setIsPromoValid(true); // 重置推广码验证状态
      setFinalCredits(selectedPackage.credits); // 重置 credits
      setReferral(false); // 重置 referral 状态
      setPromoCodeError(''); // 重置错误提示
      console.log(currency)
    }
  }, [show, selectedPackage.credits]);

  const userId = sessionStorage.getItem('userId'); // 获取 user_id

  const currencySymbols = {
    USD: '$',    // 美元
    CNY: '¥',    // 人民币
    CAD: 'C$',   // 加元
  }

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create_payment`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            user_id: userId, // 使用从 sessionStorage 中获取的 user_id
            amount: selectedPackage.price, // 转换为最小货币单位（美分）
            currency: currency
          })
        });
        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error('Error creating payment intent:', error);
      }
    };

    if (step === 2) {
      createPaymentIntent();
    }
  }, [step, selectedPackage, userId]);

  // Function to handle promo code validation
  const handlePromoCodeChange = (e) => {
    const inputPromoCode = e.target.value;
    setPromoCode(inputPromoCode);

    // Validate promo code: it should be exactly 6 characters long (letters or numbers)
    const isValid = /^[A-Za-z\d]{6}$/.test(inputPromoCode); // 允许纯字母、纯数字或混合
    setIsPromoValid(isValid || inputPromoCode === ''); // 如果输入为空或格式正确，视为有效

    // 动态计算最终的 credits
    if (isValid) {
      const baseCredits = selectedPackage.credits; // 转换为数字
      setFinalCredits(baseCredits * 1.25); // 加上50%的奖励
      setReferral(true); // 如果推广码有效，设置 referral 为 true
    } else {
      setFinalCredits(selectedPackage.credits); // 否则显示基础 credits
      setReferral(false); // 否则 referral 保持 false
    }
  };

  // Function to check promo code using refer_code_check API
  const checkPromoCode = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/refer_code_check`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: userId,
          refer_code: promoCode
        })
      });

      const data = await response.json();

      if (data.message) {
        // 如果推广码有效，将相关数据存储到 sessionStorage，并继续支付步骤
        sessionStorage.setItem('referral', referral ? 'True' : 'False');
        sessionStorage.setItem('credits', selectedPackage.credits); // 充值的基础 credits
        sessionStorage.setItem('promoCode', promoCode); // 用户输入的推广码
        setStep(2); // 进入支付步骤
      } else {
        // 如果推广码无效，显示错误提示
        setPromoCodeError("该推荐码无效，请检查！或您的账户已使用过推广码。一个用户只能使用一次推广码充值。");
      }
    } catch (error) {
      console.error('Error checking promo code:', error);
      setPromoCodeError("无法验证推荐码，请稍后再试！");
    }
  };

  const handleProceedToPayment = () => {
    // 在继续支付之前，先检查推广码是否有效
    if (isPromoValid && promoCode !== '') {
      checkPromoCode();
    } else {
      setStep(2); // 如果没有推广码或推广码无效，直接进入支付步骤
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      {step === 1 ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>{t('order_summary')}</Modal.Title> {/* 使用 t() 函数翻译 */}
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col xs={6}><strong>{finalCredits} {t('credits')}</strong></Col>
              <Col xs={6} className="text-end"><strong>{currencySymbols[currency]}{selectedPackage.price / 100}</strong></Col>
            </Row>
            <hr />
            <Row className="mb-3">
              <Col xs={6}>{t('subtotal')}</Col>
              <Col xs={6} className="text-end">{currencySymbols[currency]}{selectedPackage.price / 100}</Col>
            </Row>
            <hr />
            <Row className="mb-3">
              <Col xs={6}><strong>{t('total')}</strong></Col>
              <Col xs={6} className="text-end"><strong>{currencySymbols[currency]}{selectedPackage.price / 100}</strong></Col>
            </Row>
            {/* Promo Code Input */}
            <Row className="mb-3 align-items-center">
              <Col xs={6}><strong>{t('promo_code')}</strong></Col>
              <Col xs={5}>
                <input 
                  type="text" 
                  value={promoCode} 
                  onChange={handlePromoCodeChange} 
                  placeholder={t('enter_promo_code')} 
                  className={`form-control ${!isPromoValid && promoCode !== '' ? 'is-invalid' : ''}`} 
                />
              </Col>
              <Col xs={1}>
                {!isPromoValid && promoCode !== '' && <FaExclamationCircle color="red" />}
              </Col>
            </Row>
            {/* Promo Code Tip */}
            <Row>
              <Col xs={12} className="text-center">
                <small className="text-muted">{t('promo_code_tip')}</small>
              </Col>
            </Row>
            {/* Promo Code Error Message */}
            {promoCodeError && (
              <Alert variant="danger">
                {promoCodeError}
              </Alert>
            )}
            {/* Final Credits */}
            {isPromoValid && promoCode !== '' && (
              <Row className="mt-3">
                <Col xs={6}><strong>{t('final_credits')}</strong></Col>
                <Col xs={6} className="text-end"><strong>{finalCredits} {t('credits')}</strong></Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button 
              variant="success" 
              className="w-100" 
              onClick={handleProceedToPayment}
              disabled={!isPromoValid} 
            >
              {t('proceed_to_payment')}
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>{t('payment')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm clientSecret={clientSecret} />
              </Elements>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t('cancel')}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
  
};

export default PaymentModal;
