import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button
import { useTranslation } from 'react-i18next'; // 引入 useTranslation
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Button from 'react-bootstrap/Button';

const DetectionResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(); // 使用 useTranslation
  const { id, original_text, ai_rate, chunks, Label } = location.state || { id: '', original_text: '', ai_rate: 0, chunks: [], Label: '' };
  const chartRef = useRef(null);
  const humanizedChartRef = useRef(null);

  const [humanizedText, setHumanizedText] = useState('');
  const [isHumanizing, setIsHumanizing] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [humanizedAIrate, setHumanizedAIrate] = useState(null);
  const [rewrittenChunks, setRewrittenChunks] = useState([]);
  const [selectedMode, setSelectedMode] = useState('easy'); // 初始化状态为 'easy'
  const [showModal, setShowModal] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [creditCost, setCreditCost] = useState(0);

  const syncBalance = async () => {
    try {
      const userId = sessionStorage.getItem('userId'); // 获取 user_id
      if (userId) {
        // Sync balance after referral code logic
        const balanceResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/balance`, { user_id: userId });
        const newBalance = balanceResponse.data.balance;
        sessionStorage.setItem('balance', newBalance.toString());
        window.dispatchEvent(new Event('balanceUpdated'));
      }
    } catch (error) {
      console.error(t('error_updating_balance'), error);
    }
  };

  useEffect(() => {
    syncBalance(); // 在渲染时同步余额
    try {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
      const ctx = document.getElementById('smallDonutChart').getContext('2d');
      chartRef.current = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: [t('ai'), t('human')],
          datasets: [{
            data: [ai_rate, 100 - ai_rate],
            backgroundColor: ['#ff6384', '#36a2eb'],
            borderWidth: 0
          }]
        },
        options: {
          cutout: '70%',
          plugins: {
            legend: { display: false },
            tooltip: { enabled: false }
          },
          maintainAspectRatio: false,
          events: []
        }
      });
    } catch (error) {
      console.error(t('error_chart'), error);
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [ai_rate, t]);

  const highlightText = (text, chunks, highlightColor = 'rgba(54, 162, 235, 0.2)') => {
    return text.split('\n').map((paragraph, i) => (
      <p key={i}>
        {paragraph.split(/(?<=[.?!])\s+/).map((sentence, index) => (
          chunks.includes(sentence)
            ? <span key={index} style={{ backgroundColor: highlightColor, fontWeight: 'bold' }}>{sentence + ' '}</span>
            : <span key={index}>{sentence + ' '}</span>
        ))}
      </p>
    ));
  };

  const calculateWords = (text) => {
    return text.trim().split(/\s+/).length;
  };
  const calculateCreditCost = (wordCount, mode) => {
    let rate;
    switch (mode) {
      case 'easy':
        rate = 1.1;
        break;
      case 'medium':
        rate = 1.1;
        break;
      case 'aggressive':
        rate = 1.1;
        break;
      default:
        rate = 1.1;
    }
    return wordCount * rate;
  };

  const handleOpenModal = () => {
    const count = calculateWords(original_text);
    const cost = calculateCreditCost(count, selectedMode);
    setWordCount(count);
    setCreditCost(cost);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleHumanize = async (mode = 'medium') => {
    setIsHumanizing(true);
    setProgressMessage(t('progress_humanize'));

    const userId = sessionStorage.getItem('userId');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/humanize_chunks`, {
        user_id: userId,
        mode,
        chunks: chunks,
        origin_text: original_text
      });

      const { chunks: rewrittenChunks, text: newHumanizedText } = response.data;
      setHumanizedText(newHumanizedText);
      setRewrittenChunks(rewrittenChunks);

      setProgressMessage(t('progress_calculating_ai'));

      const aiCheckResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/check`, {
        user_id: userId,
        origin_text: newHumanizedText
      });

      const newAIrate = aiCheckResponse.data.data?.AI || 0;
      setHumanizedAIrate(newAIrate);

      if (humanizedChartRef.current) {
        humanizedChartRef.current.destroy();
      }
      const ctx = document.getElementById('humanizedDonutChart').getContext('2d');
      humanizedChartRef.current = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: [t('ai'), t('human')],
          datasets: [{
            data: [newAIrate, 100 - newAIrate],
            backgroundColor: ['#ff6384', '#36a2eb'],
            borderWidth: 0
          }]
        },
        options: {
          cutout: '70%',
          plugins: {
            legend: { display: false },
            tooltip: { enabled: false }
          },
          maintainAspectRatio: false,
          events: []
        }
      });

      await syncBalance(); // 同步余额
    } catch (error) {
      console.error(t('error_humanize'), error);
    }

    setIsHumanizing(false);
    setProgressMessage('');
  };

  const handleConfirm = () => {
    setShowModal(false);
    handleHumanize(selectedMode); // Proceed with humanization
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert(t('copied_to_clipboard'));
    }).catch(err => {
      console.error(t('error_copying'), err);
    });
  };

  return (
    <div className="container-fluid mt-5">
      <h1 className="text-center text-success">{t('detection_result')}</h1>


      <div className="container" style={{ maxWidth: '800px', padding: '0 15px' }}>
  <div className="alert alert-primary p-4" role="alert">
    {t('refine_rewrite')}
  </div>
</div>




      <div className="text-center my-4">
        <Dropdown as={ButtonGroup}>
          {/* Main Button */}
          <Button
            className="btn btn-warning d-flex align-items-center"
            onClick={handleOpenModal}
            disabled={isHumanizing}
          >
            <i className="bi bi-arrow-right-circle-fill me-2"></i> {/* Icon */}
            {t('humanize')} - {t(selectedMode)} Mode {/* Display Humanize with Mode */}
          </Button>

          {/* Split Toggle Button */}
          <Dropdown.Toggle
            split
            className="btn btn-warning"
            id="dropdown-split-basic"
          />

          {/* Dropdown Menu */}
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setSelectedMode('easy')}>{t('easy')}</Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedMode('medium')}>{t('medium')}</Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedMode('aggressive')}>{t('aggressive')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {isHumanizing && (
        <div className="progress mb-4" style={{ height: '30px' }}>
          <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: '100%' }}>
            {progressMessage}
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirm_credits_usage')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('credits_confirmation', { credits: creditCost.toFixed(1), wordCount })}</p>
          <p>{t('refine_rewrite')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('cancel')}
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            {t('confirm')}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row" style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <div className="col-md-6">
          <div className="card mb-4 shadow-sm" style={{ backgroundColor: 'white' }}>
            <div className="card-body d-flex align-items-center">
              <div style={{ width: '30px', height: '30px', marginRight: '10px', position: 'relative' }}>
                <canvas id="smallDonutChart" style={{ width: '30px', height: '30px' }}></canvas>
              </div>
              <h3 className="card-title mb-0">{t('original_text')}</h3>
            </div>
          </div>
          <div className="card mb-4 shadow-sm" style={{ backgroundColor: 'white' }}>
            <div className="card-body">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between mb-2">
                  <div style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                    {t('likely_written_by')} {Label}.
                  </div>
                  <div style={{ fontSize: '1.5rem', fontWeight: 'bold', color: ai_rate > 30 ? '#ff6384' : '#36a2eb' }}>
                    {ai_rate}%
                  </div>
                </div>
                <div style={{ fontSize: '0.9rem', color: '#555' }}>
                  {chunks.length}/{original_text.split(/(?<=[.?!])\s+/).length} {t('likely_ai_generated')}
                </div>
              </div>
              <div id="original-text">{highlightText(original_text, chunks)}</div>
            </div>
          </div>
        </div>
        {humanizedText && (
          <div className="col-md-6">
            <div className="card mb-4 shadow-sm" style={{ backgroundColor: 'white' }}>
              <div className="card-body d-flex align-items-center">
                <div style={{ width: '30px', height: '30px', marginRight: '10px', position: 'relative' }}>
                  <canvas id="humanizedDonutChart" style={{ width: '30px', height: '30px' }}></canvas>
                </div>
                <h3 className="card-title mb-0">{t('humanized_text')}</h3>
                <button className="btn btn-outline-secondary btn-sm ms-2" onClick={() => copyToClipboard(humanizedText)} 
                  style={{ 
                    borderRadius: '50px', 
                    transition: 'background-color 0.3s, color 0.3s', 
                    padding: '0.25rem 0.75rem' 
                  }}>
                  {t('copy')}
                </button>
              </div>
            </div>
            <div className="card mb-4 shadow-sm" style={{ backgroundColor: 'white' }}>
              <div className="card-body">
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between mb-2">
                    <div style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                      {t('humanized_ai_rate')}
                    </div>
                    <div style={{ fontSize: '1.5rem', fontWeight: 'bold', color: humanizedAIrate > 30 ? '#ff6384' : '#36a2eb' }}>
                      {humanizedAIrate}%
                    </div>
                  </div>
                  <div style={{ fontSize: '0.9rem', color: '#555' }}>
                    {rewrittenChunks.length}/{humanizedText.split(/(?<=[.?!])\s+/).length} {t('likely_ai_generated')}
                  </div>
                </div>
                <div id="humanized-text">{highlightText(humanizedText, rewrittenChunks, 'rgba(54, 162, 235, 0.2)')}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetectionResult;
