// utils/DocToQrCode.js

import { useState, useEffect } from 'react';

/**
 * 通过文档生成 QR 代码的 Hook
 * @param {string} docUrl - 文档 URL
 * @param {number} [pollingInterval=10000] - 轮询间隔时间（毫秒）
 * @returns {string} - 生成的 QR 代码 URL
 */
export const useDocToQrCode = (docUrl, pollingInterval = 10000) => {
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [previousContent, setPreviousContent] = useState('');

  useEffect(() => {
    const fetchDocumentContent = async () => {
      try {
        const response = await fetch(docUrl);
        const content = await response.text();

        if (content.trim() !== previousContent) {
          setPreviousContent(content.trim());
          setQrCodeUrl(
            `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${encodeURIComponent(
              content.trim()
            )}`
          );
        }
      } catch (error) {
        console.error('Error fetching document content:', error);
      }
    };

    const interval = setInterval(fetchDocumentContent, pollingInterval);
    fetchDocumentContent(); // 初始化调用

    return () => clearInterval(interval); // 清理定时器
  }, [docUrl, pollingInterval, previousContent]);

  return qrCodeUrl;
};
